import { Tooltip } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import modelConfig from '../../config/model.config';
import { LocationContext } from '../../contexts/location.context';
import { SettingsContext } from '../../contexts/settings.context';
import { ReactComponent as SettingsIcon } from '../../svg/settings.svg';
import { ReactComponent as ZoomInIcon } from '../../svg/zoom_in.svg';
import { ReactComponent as ZoomOutIcon } from '../../svg/zoom_out.svg';
import SettingsMenu from '../SettingsMenu/SettingsMenu';
import './ControlMenu.scss';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const ControlMenu = () => {
    const [settingsMenuActive, setSettingsMenuActive] = useState(false);
    const { cameraDistance, setCameraDistance } = useContext(SettingsContext);
    const { currentLocation } = useContext(LocationContext);

    const minDistance = modelConfig.camera.distance.min;
    const maxDistance = modelConfig.camera.distance.max;

    const zoomOutDisabled = cameraDistance === minDistance || !currentLocation.isTopLevel;
    const zoomInDisabled = cameraDistance === maxDistance || !currentLocation.isTopLevel;

    const zoomOut = () => {
        if (cameraDistance < minDistance) {
            setCameraDistance(cameraDistance + 10);
        }
    }

    const zoomIn = () => {
        if (cameraDistance > maxDistance) {
            setCameraDistance(cameraDistance - 10)
        }
    }




    return (
        <div className="control-menu">
            <SettingsMenu active={settingsMenuActive} />

            <Tooltip title="Instellingen">
                <div
                    className="control-menu__button control-menu__button--settings"
                    onClick={() => setSettingsMenuActive(!settingsMenuActive)}>
                    <SettingsIcon />
                </div>
            </Tooltip>

            <Tooltip title="Uitzoomen">
                <div className={`control-menu__button control-menu__button--zoom-out ${zoomOutDisabled ? 'control-menu__button--disabled' : ""}`} onClick={zoomOut}>
                    <ZoomOutIcon />
                </div>
            </Tooltip>

            <Tooltip title="Inzoomen">
                <div className={`control-menu__button control-menu__button--zoom-in ${zoomInDisabled ? 'control-menu__button--disabled' : ""}`} onClick={zoomIn}>
                    <ZoomInIcon />
                </div>
            </Tooltip>
        </div>
    )
}

export default ControlMenu;