import React from 'react';
import Logo from './components/Logo/Logo';
import TopBar from './components/TopBar/TopBar';
import Sidebar from './components/Sidebar/Sidebar';
import ControlMenu from './components/ControlMenu/ControlMenu';
import TwindleCanvas from './components/TwindleCanvas/TwindleCanvas';
import Meshes from './components/Meshes/Meshes';
import LocationTags from './components/LocationTags/LocationTags';
import Lightning from './components/Lightning/Lightning';
import CanvasControls from './components/CanvasControls/CanvasControls';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

function App() {
  return (
    <>
      <Logo />
      <TopBar />
      <Sidebar />
      <ControlMenu />
      <TwindleCanvas fallback={null}>
        <Meshes />
        <LocationTags />
        <Lightning />
        <CanvasControls />
      </TwindleCanvas>
    </>
  );
}

export default App;
