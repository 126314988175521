import { ReactElement } from 'react';
import { SIDEBARROUTE } from "../../types/sidebar.types"

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const SidebarView = (props: { id: SIDEBARROUTE, component: ReactElement }) => {
    return props.component;
}

export default SidebarView;