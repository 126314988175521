import React, { useContext } from 'react';
import { SettingsContext } from '../../contexts/settings.context';
import { ReactComponent as RotateIcon } from '../../svg/rotate.svg'
import { ReactComponent as LocatorIcon } from '../../svg/locator.svg'
import { ReactComponent as TagIcon } from '../../svg/label.svg'
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import './SettingsMenu.scss';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const SettingsMenu = (props: { active: boolean }) => {
    const { autoRotate, showActivity, hideTags } = useContext(SettingsContext);
    const { setAutoRotate, setShowActivity, setHideTags } = useContext(SettingsContext);


    return (
        <div className="settings-menu__wrapper">
            {props.active && <div className="settings-menu">
                <div className="settings-menu__item">
                    <span className="settings-menu__item__icon"><RotateIcon /></span>
                    <span className="settings-menu__item__title">Automatisch draaien</span>
                    <Toggle onChange={() => setAutoRotate(!autoRotate)} checked={autoRotate} title="Automatisch draaien" icons={false} className="settings-menu__item__toggle"></Toggle>
                </div>
                <div className="settings-menu__item">
                    <span className="settings-menu__item__icon"><LocatorIcon /></span>
                    <span className="settings-menu__item__title">Brandweermodus</span>
                    <Toggle onChange={() => setShowActivity(!showActivity)} checked={showActivity} title="Automatisch draaien" icons={false} className="settings-menu__item__toggle"></Toggle>
                </div>
                <div className="settings-menu__item">
                    <span className="settings-menu__item__icon"><TagIcon /></span>
                    <span className="settings-menu__item__title">Verberg tags</span>
                    <Toggle onChange={() => setHideTags(!hideTags)} checked={hideTags} title="Automatisch draaien" icons={false} className="settings-menu__item__toggle"></Toggle>
                </div>
            </div>}
        </div>
    )
}

export default SettingsMenu;
