import './style/style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApiProvider } from './contexts/api.context';
import App from './App';
import { TwindleProvider } from './contexts/twindle.context'
import { LocationProvider } from './contexts/location.context';
import { SidebarProvider } from './contexts/sidebar.context';
import { SettingsProvider } from './contexts/settings.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

ReactDOM.render(
	<React.StrictMode>
		<ApiProvider>
			<TwindleProvider>
				<LocationProvider>
					<SidebarProvider>
						<SettingsProvider>
							<App />
						</SettingsProvider>
					</SidebarProvider>
				</LocationProvider>
			</TwindleProvider>
		</ApiProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
