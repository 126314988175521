import React, { ReactNode, useContext, useEffect, useState } from 'react';
import modelConfig from '../config/model.config';
import { ISettingsContext } from '../types/context.types';
import { LocationContext } from './location.context';
import { SidebarContext } from './sidebar.context';
import { TwindleContext } from './twindle.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const SettingsContext = React.createContext({} as ISettingsContext);

export const SettingsProvider = (props: { children: ReactNode }) => {
    const [autoRotate, setAutoRotate] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [hideTags, setHideTags] = useState(false);
    const [cameraDistance, setCameraDistance] = useState(modelConfig.camera.distance.floor);


    const value = {
        autoRotate, setAutoRotate,
        showActivity, setShowActivity,
        hideTags, setHideTags,
        cameraDistance, setCameraDistance,
    };

    // Context hooks
    const { twindle } = useContext(TwindleContext);
    const { currentLocation, setCurrentLocation } = useContext(LocationContext);
    const { setSidebarOpen } = useContext(SidebarContext);

    // When showing active rooms, make the model readable
    useEffect(() => {
        if (showActivity === true) {
            setCurrentLocation(twindle.find(location => location.isTopLevel === true));
            setAutoRotate(false)
            setHideTags(false)
            setSidebarOpen(false);
        }
    }, [showActivity, twindle, setCurrentLocation, setSidebarOpen]);

    // Stop autorotate on room focus
    useEffect(() => {
        if (currentLocation.isTopLevel !== true) {
            setAutoRotate(false);
        }
    }, [currentLocation]);

    return (
        <SettingsContext.Provider value={value}>
            {props.children}
        </SettingsContext.Provider>
    )
}
