import React, { ReactElement, useContext } from 'react';
import { SidebarContext } from '../../contexts/sidebar.context';
import SidebarView from './SidebarView';


//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const SidebarViews = (props: { children: ReactElement<typeof SidebarView> | ReactElement<typeof SidebarView>[] }) => {
    const { sidebarData } = useContext(SidebarContext);
    const currentRoute = React.Children.map(props.children, (child: any, index) => {
        return (sidebarData.route === child.props.id) && child
    })

    return (<>{currentRoute}</>)
}

export default SidebarViews;
