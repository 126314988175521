import { Tooltip } from '@material-ui/core';
import React, { useContext } from 'react';
import { LocationContext } from '../../contexts/location.context';
import { SidebarContext } from '../../contexts/sidebar.context';
import { TwindleContext } from '../../contexts/twindle.context';
import { ReactComponent as LevelUpIcon } from '../../svg/level_up.svg';
import './TopBar.scss';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const TopBar = () => {
    const { currentLocation, setCurrentLocation } = useContext(LocationContext);
    const { setSidebarOpen, sidebarOpen } = useContext(SidebarContext);
    const { twindle } = useContext(TwindleContext);

    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const goToTop = () => {
        setCurrentLocation(twindle.find(location => location.isTopLevel === true))
    }

    return (
        <div className="top-bar__wrapper">
            <Tooltip title={!sidebarOpen ? `Bekijk zijbalk` : ''}>
                <div className="top-bar">
                    {!currentLocation.isTopLevel &&
                        <Tooltip title="Terug naar verdieping">
                            <div className="top-bar__up-btn" onClick={goToTop}><LevelUpIcon /></div>
                        </Tooltip>
                    }
                    <div className="top-bar__display" onClick={openSidebar}>
                        {currentLocation.status &&
                            <Tooltip title={`Status: ${currentLocation.status}`}>
                                <span className={`top-bar__status status status--${currentLocation.status}`}></span>
                            </Tooltip>
                        }
                        <p className="top-bar__title">
                            {currentLocation.displayName}
                        </p>
                    </div>
                </div>
            </Tooltip>
        </div>
    )
}

export default TopBar;