import React from 'react';
import { ITabProps } from '../../types/tab.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Tab = (props: ITabProps) => {
    return <>{props.children}</>
}

export default Tab;