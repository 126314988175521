import React, { useContext } from 'react';
import { Html, useGLTF } from 'drei';
import { TwindleContext } from '../../contexts/twindle.context';
import { LocationContext } from '../../contexts/location.context';
import { SidebarContext } from '../../contexts/sidebar.context';
import { SettingsContext } from '../../contexts/settings.context';
import modelConfig from '../../config/model.config';
import LocationTag from './LocationTag';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const LocationTags = () => {
    const { twindle } = useContext(TwindleContext);
    const model = useGLTF(modelConfig.path.rooms, true);
    const modelLocations = model.scene.children.filter(object => object.userData['type'] === "room");
    const modelNames = modelLocations.map(object => object.name);

    const tagLocations = twindle.filter(location => {
        if (location.isTopLevel !== true && modelNames.includes(location.id)) {
            const modelLocation = modelLocations.find(object => object.name === location.id);
            location.position = modelLocation.position;

            return location;
        }
        else return undefined;
    })

    // Drei Html element needs context passed via constructor
    // because useContext does not work.
    const twindleContext = useContext(TwindleContext);
    const locationContext = useContext(LocationContext);
    const sidebarContext = useContext(SidebarContext);
    const settingsContext = useContext(SettingsContext);

    return (
        <>
            {tagLocations.map(location => (
                <Html position={[location.position.x, modelConfig.tagHeight, location.position.z]} key={location.id}>
                    <LocationTag
                        location={location}
                        twindleContext={twindleContext}
                        locationContext={locationContext}
                        sidebarContext={sidebarContext}
                        settingsContext={settingsContext} />
                </Html>
            ))}
        </>
    )
}

export default LocationTags;