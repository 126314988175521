//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export interface ISidebarData {
	route: SIDEBARROUTE;
	maintab: MAINTAB;
	locationtab: LOCATIONTAB;
	metric?: any;
	layer?: any;
	description?: string;
}

export enum SIDEBARROUTE {
	MAIN = 'main',
	LOCATION = 'location',
	METRIC = 'metric',
	LAYER = 'layer',
}

export enum MAINTAB {
	OVERVIEW = 'overview',
	NOTIFICATIONS = 'notifications',
	LOCATIONS = 'locations',
}

export enum LOCATIONTAB {
	OVERVIEW = 'overview',
	METRICS = 'metrics',
}
