import { Vector3 } from 'three';
import apiConfig from '../../config/api.config';
import { ILocationConfig } from '../../types/configuration.types';
import { STATUS } from '../../types/status.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export class TwindleLocation {
	// Constructor values
	readonly id: ILocationConfig['id'];
	readonly displayName: ILocationConfig['displayName'];
	readonly shortName: ILocationConfig['shortName'];
	readonly sensor_uids: ILocationConfig['sensor_uids'];
	readonly isTopLevel: ILocationConfig['isTopLevel'];

	// Api url
	apiUrl?: { latest: string; log: string };

	// Variable private properties
	private _status!: STATUS;
	private _layers!: any[];
	private _metrics!: any[];
	private _logs!: { day: any; week: any };
	private _description!: string;

	// Twindle context states
	public twndl?: TwindleLocation[];
	public setTwndl?: React.Dispatch<React.SetStateAction<TwindleLocation[]>>;
	public update() {
		if (this.twndl) {
			const index = this.twndl.findIndex((value) => value.id === this.id);
			const newTwndl = this.twndl;
			// update twindle
			newTwndl[index] = this;
			// dispatch update
			this.setTwndl([...newTwndl]);
		}
	}

	// 3D position
	public position: Vector3 = new Vector3(0, 0, 0);

	constructor(location: ILocationConfig) {
		this.id = location.id;
		this.displayName = location.displayName;
		this.shortName = location.shortName;
		this.sensor_uids = location.sensor_uids;
		this.isTopLevel = location.isTopLevel;

		this.initializeTwindleLocation();
	}

	initializeTwindleLocation() {
		this.apiUrl = {
			latest: `${apiConfig.apiUrl}/latest?id=${this.id}`,
			log: `${apiConfig.apiUrl}/log?id=${this.id}`,
		};

		this.fetchLatest(this);
		this.fetchLogs(this);

		setInterval(() => {
			this.fetchLatest(this);
			this.fetchLogs(this);
		}, 240000);
	}

	// Fetch data from api
	public fetchLatest(_location: TwindleLocation) {
		fetch(this.apiUrl.latest)
			.then((res) => res.json())
			.then((result) => {
				this.metrics = result.metrics;
				this.layers = result.layers;
				this.status = result.status;
			})
			.catch((err: Error) => {
				console.error(err.message);
			});
	}

	public async fetchLogs(_location: TwindleLocation) {
		const day = 86400000;
		const week = 86400000 * 7;

		// fetch day
		const dayLog = await fetch(
			this.apiUrl.log + `&from=${Date.now() - day}&to=${Date.now()}`
		)
			.then((res) => res.json())
			.then((result) => {
				return result;
			})
			.catch((err: Error) => {
				console.error(err.message);
			});

		const weekLog = await fetch(
			this.apiUrl.log + `&from=${Date.now() - week}&to=${Date.now()}`
		)
			.then((res) => res.json())
			.then((result) => {
				return result;
			})
			.catch((err: Error) => {
				console.error(err.message);
			});

		this.logs = { day: await dayLog, week: await weekLog };
	}

	// Status property
	public get status() {
		return this._status;
	}
	public set status(value) {
		this._status = value;
		this.update();
	}

	// Metrics property
	public get metrics() {
		return this._metrics;
	}
	public set metrics(value) {
		this._metrics = value;
		this.update();
	}

	// Logs property
	public get logs() {
		return this._logs;
	}
	public set logs(value) {
		this._logs = value;
		this.update();
	}

	// Layers property
	public get layers() {
		return this._layers;
	}
	public set layers(value) {
		this._layers = value;
		this.update();
	}

	// Description property
	public get description() {
		return this._description;
	}
	public set description(value) {
		this._description = value;
		this.update();
	}
}
