//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export enum STATUS {
	OK = 'ok',
	WARNING = 'warning',
	DANGER = 'danger',
	TRUE = 1,
	FALSE = 0,
	PRESENT = 'present',
	ABSENT = 'absent',
}

export enum STATUSTYPE {
	TRINARY = 'trinary',
	DOOR = 'door',
	PARKING = 'parking',
	PRESENCE = 'presence',
}
