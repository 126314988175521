//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const apiConfig = {
    apiUrl: "https://api-postillion.twindle.io",
    // apiUrl: "http://localhost:4040"
}

export default apiConfig;