import React from 'react';


//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const Lightning = () => {

    return (<>
        {/* <directionalLight castShadow={false} intensity={.8} position={[5, 5, 5]} /> */}
        <pointLight castShadow={false} intensity={.5} position={[-100,80,110]} />
        <pointLight castShadow={true} intensity={.7} position={[-100,80,110]} />
        <ambientLight intensity={.7}/>
    </>);
}

export default Lightning;