import React, { ReactNode, Suspense, useContext } from 'react';
import { Canvas } from 'react-three-fiber';
import { TwindleContext } from '../../contexts/twindle.context';
import { LocationContext } from '../../contexts/location.context';
import { SidebarContext } from '../../contexts/sidebar.context';
import { SettingsContext } from '../../contexts/settings.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const TwindleCanvas = (props: { children: ReactNode, fallback: ReactNode }) => {
    const twindleContext = useContext(TwindleContext);
    const locationContext = useContext(LocationContext);
    const sidebarContext = useContext(SidebarContext);
    const settingsContext = useContext(SettingsContext);

    return (
        <Canvas
            id="canvas"
            gl={{ antialias: true, alpha: true }}
            camera={{ position: [1, 1, 0], fov: 75 }}
            // onCreated={({ gl }) => gl.setClearColor('#52abff')}
            style={{ position: "absolute" }}
            shadowMap={true}
        >
            <TwindleContext.Provider value={twindleContext}>
                <LocationContext.Provider value={locationContext}>
                    <SidebarContext.Provider value={sidebarContext}>
                        <SettingsContext.Provider value={settingsContext}>
                            <Suspense fallback={props.fallback}>
                                {props.children}
                            </Suspense>
                        </SettingsContext.Provider>
                    </SidebarContext.Provider>
                </LocationContext.Provider>
            </TwindleContext.Provider>
        </Canvas>
    )
}

export default TwindleCanvas;