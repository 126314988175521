import React, { useContext } from 'react';
import { LocationContext } from '../../contexts/location.context';
// import { TwindleContext } from '../../contexts/twindle.context';
import { SidebarContext } from '../../contexts/sidebar.context';
import { CARDMETRICTYPE } from '../../types/cardmetric.types';
// import { TwindleLocation } from '../../services/TwindleLocation/TwindleLocation';
// import { STATUS } from '../../types/status.types';
import { LOCATIONTAB } from '../../types/sidebar.types';
import Card from '../Card/Card';
import CardMetric from '../CardMetric/CardMetric';
import Tab from '../Tabs/Tab';
import Tabs from '../Tabs/Tabs';
// import CardMetric from '../CardMetric/CardMetric';
// import { CARDMETRICTYPE } from '../../types/cardmetric.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const SidebarLocationView = () => {
    // Contexts
    const { sidebarData, setSidebarData } = useContext(SidebarContext);
    const { currentLocation } = useContext(LocationContext);
    // const { twindle } = useContext(TwindleContext);


    // Enabled tab
    const tabIsOverview = sidebarData.locationtab === LOCATIONTAB.OVERVIEW;
    const tabIsMetrics = sidebarData.locationtab === LOCATIONTAB.METRICS;

    // Save selected location tab
    const selectLocationTab = (event: React.MouseEvent) => {
        const selectedTab = event.currentTarget.id as LOCATIONTAB;
        setSidebarData({ ...sidebarData, locationtab: selectedTab })
    }

    return (
        <Tabs>
            <Tab
                id={LOCATIONTAB.OVERVIEW}
                label="Overzicht"
                enabled={tabIsOverview}
                customEvent={selectLocationTab}>
                <Card>
                    {currentLocation.layers && currentLocation.layers.map((layer, index) => (
                        typeof layer.status !== 'undefined' && (
                            <CardMetric key={layer.id} metric={layer} layer={layer} status={layer.status} type={CARDMETRICTYPE.LAYER} />
                        )
                    ))}
                </Card>
            </Tab>
            <Tab
                id={LOCATIONTAB.METRICS}
                label="Meetwaarden"
                enabled={tabIsMetrics}
                customEvent={selectLocationTab}>
                <Card>
                    {currentLocation.metrics && currentLocation.metrics.map((metric, index) => (
                        <CardMetric key={index} metric={metric} status={metric.status} type={CARDMETRICTYPE.METRIC} />
                    ))}
                </Card>
            </Tab>
        </Tabs>
    )
}

export default SidebarLocationView;