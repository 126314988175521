//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const modelConfig = {
	path: {
		meshes: './models/meshes/twindle-postillion.gltf',
		rooms: './models/rooms/twindle-postillion.gltf',
	},
	tagHeight: 2.8,
	camera: {
		distance: {
			max: 10,
			min: 40,
			default: 40,
			floor: 40,
			room: 10,
		},
		clamp: {
			default: Math.PI / 5,
			floor: Math.PI / 3.5,
			room: Math.PI / 10,
		},
	},
};

export default modelConfig;
