import React, { useRef, useContext } from 'react';
import modelConfig from '../../config/model.config';
import { LocationContext } from '../../contexts/location.context'
import { OrbitControls } from 'drei';
import { useSpring } from 'react-spring';
import { Vector3 } from 'three';
import { SettingsContext } from '../../contexts/settings.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

const CanvasControls = () => {
    // Controls ref
    const controlsRef = useRef<OrbitControls>();

    // Location context
    const { currentLocation } = useContext(LocationContext);
    const { autoRotate, cameraDistance } = useContext(SettingsContext);

    useSpring({
        config: { mass: 4, tension: 180, friction: 40, velocity: 0 },
        from: {
            positionX: currentLocation.position.x,
            positionY: currentLocation.position.y,
            positionZ: currentLocation.position.z,
            zoomLevel: currentLocation.isTopLevel ? cameraDistance : modelConfig.camera.distance.room,
            clampAngle: !currentLocation.isTopLevel ?
                modelConfig.camera.clamp.room : modelConfig.camera.clamp.floor
        },
        to: {
            positionX: currentLocation.position.x,
            positionY: currentLocation.position.y,
            positionZ: currentLocation.position.z,
            zoomLevel: currentLocation.isTopLevel ? cameraDistance : modelConfig.camera.distance.room,
            clampAngle: currentLocation.isTopLevel ?
                modelConfig.camera.clamp.floor : modelConfig.camera.clamp.room
        },
        onFrame(target: { positionX: number, positionY: number, positionZ: number, zoomLevel: number, clampAngle: number }) {
            const controls = controlsRef.current

            if (typeof controls !== "undefined") {
                controls.target = new Vector3(target.positionX, target.positionY, target.positionZ);
                controls.minDistance = target.zoomLevel;
                controls.maxDistance = target.zoomLevel;
                controls.maxPolarAngle = target.clampAngle;
                controls.minPolarAngle = target.clampAngle;
            }
        }
    })



    return <OrbitControls ref={controlsRef}
        autoRotate={autoRotate}
        autoRotateSpeed={0.5}
        minPolarAngle={modelConfig.camera.clamp.default}
        maxPolarAngle={modelConfig.camera.clamp.default}
    />
}

export default CanvasControls;