import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ISidebarContext } from '../types/context.types';
import { LocationContext } from './location.context';
import { ISidebarData, LOCATIONTAB, MAINTAB, SIDEBARROUTE } from '../types/sidebar.types';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const SidebarContext = React.createContext({} as ISidebarContext);

export const SidebarProvider = (props: { children: ReactNode }) => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
    const [sidebarData, setSidebarData] = useState<ISidebarData>({} as ISidebarData);
    const { currentLocation } = useContext(LocationContext);

    // Initial sidebar data
    useEffect(() => {
        const initialSidebarData = {
            route: SIDEBARROUTE.MAIN,
            maintab: MAINTAB.OVERVIEW,
            locationtab: LOCATIONTAB.OVERVIEW,
        }
        setSidebarData(initialSidebarData);
    }, []);

    // Set sidebar to overview route on top location
    useEffect(() => {
        if (currentLocation.isTopLevel) {
            setSidebarData(s => s = { ...s, route: SIDEBARROUTE.MAIN })
        } else {
            setSidebarData(s => s = { ...s, route: SIDEBARROUTE.LOCATION })
        }
    }, [currentLocation]);

    const value = {
        sidebarOpen, setSidebarOpen,
        sidebarData, setSidebarData
    }

    return (
        <SidebarContext.Provider value={value}>
            {props.children}
        </SidebarContext.Provider>
    )
}