import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { ITwindleContext } from '../types/context.types';
import { TwindleLocation } from '../services/TwindleLocation/TwindleLocation';
import { ApiContext } from './api.context';

//
//
// Twindle © • Handpicked Agencies
// Handpicked Labs
//
//

export const TwindleContext = React.createContext({} as ITwindleContext);

export const TwindleProvider = (props: { children: ReactNode }) => {
    // Twndl state
    const [twindle, setTwindle] = useState<TwindleLocation[] | undefined>(undefined);

    // Api context hook
    const { locationConfig } = useContext(ApiContext);

    // Effect hooks
    useEffect(() => {
        if (locationConfig) {
            let configArray: TwindleLocation[] = []

            locationConfig.forEach(location => {
                configArray.push(new TwindleLocation(location));
            })

            setTwindle(configArray);
        }
    }, [locationConfig]);

    useEffect(() => {
        if (twindle) {
            twindle.forEach(location => {
                location.twndl = twindle;
                location.setTwndl = setTwindle;
            })
        }
    }, [twindle]);

    // Context values
    const value = { twindle, setTwindle }

    if (typeof twindle === "undefined") {
        return <div>No twndl</div>
    } else {
        return <TwindleContext.Provider value={value} children={props.children} />
    }
}

